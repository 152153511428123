import { InternalAxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/vue'

export async function sentryLogging(request: InternalAxiosRequestConfig) {
    return Sentry.startSpan(
        { op: 'http.client', name: [request?.method, request.url].filter(Boolean).join(' ') },
        async (span) => {
            const parsedURL = new URL(request.url || '', window.location.origin)

            span.setAttribute('http.request.method', request?.method)
            span.setAttribute('http.request.data', request?.data)
            span.setAttribute('server.address', parsedURL.hostname)
            span.setAttribute('server.port', parsedURL.port || undefined)

            const response = await fetch(request?.url || '', { method: request.method, body: request?.data })

            span.setAttribute('http.response.status_code', response.status)
            span.setAttribute('http.response_content_length', Number(response.headers.get('content-length')))

            // A good place to set other span attributes
            return request
        }
    )
}

export default sentryLogging
